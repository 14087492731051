/* eslint-disable @typescript-eslint/no-empty-function */
import faBullhorn from "icons/duotone/bullhorn.svg";
import Icon from "hardcover-ui/components/Icon";
import LoadingSpinner from "hardcover-ui/components/LoadingSpinner";

export default function MyLibraryLinkInactive({
  onClick = () => {},
  loading = false,
}: {
  onClick?: () => void;
  loading?: boolean;
}) {
  return (
    <button type="button" className="text-indigo-500" onClick={onClick}>
      {loading ? (
        <LoadingSpinner size="md" />
      ) : (
        <Icon icon={faBullhorn} size="xl" />
      )}
    </button>
  );
}
