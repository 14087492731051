"use client";

import { Suspense, lazy, useState } from "react";
import ShareButtonInactive from "./ShareButtonInactive";

const ShareButtonActive = lazy(() => import("./ShareButtonActive"));

export default function ShareButton() {
  const [clicked, setClicked] = useState(false);

  // Don't load Headless UI and the entire dropdown component unless it's clicked on
  if (clicked) {
    return (
      <Suspense fallback={<ShareButtonInactive loading />}>
        <ShareButtonActive />
      </Suspense>
    );
  }

  return <ShareButtonInactive onClick={() => setClicked(true)} />;
}
