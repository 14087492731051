export type AvatarThemeProps = {
  subject: "user" | "author";
  variant: "normal" | "bordered";
  size: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "full";
  dimensions: "sm" | "md" | "lg" | "xl" | "2xl" | "full";
};

export const avatarTheme = {
  variant: {
    normal: "inline-block overflow-hidden",
    bordered: "overflow-hidden border border-gray-700 dark:border-gray-50",
  },
  size: {
    xs: "rounded-full",
    sm: "rounded-full",
    md: "rounded-full",
    lg: "rounded-full",
    xl: "rounded-xl",
    "2xl": "rounded-xl",
    full: "",
  },
  dimensions: {
    xs: 20,
    sm: 30,
    md: 40,
    lg: 60,
    xl: 80,
    "2xl": 180,
    full: 500,
  },
};
