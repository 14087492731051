"use client";

import { ReactNode } from "react";
import classNames from "lib/classNames";
import { MAIN_NAVIGATION_HEIGHT } from "constants/navigation";
import useTransparentNav from "features/Nav/hooks/useTransparentNav";
import { useIsNavVisible } from "features/Nav/hooks/useIsNavVisible";

const NavWrapper = ({ children }: { children: ReactNode }) => {
  const { top, isNavVisible } = useIsNavVisible();
  const isTransparent = useTransparentNav();

  return (
    <nav
      className={classNames(
        "w-full top-0 z-30 ease-in-out duration-200 sticky mx-aut0",
        isTransparent ? "" : "bg-white dark:bg-gray-800",
        isTransparent && top ? "text-white" : "",
        !top && isNavVisible ? "bg-white dark:bg-gray-800" : "bg-transparent",
        isNavVisible ? "translate-y-0" : "translate-y-[-100%]"
      )}
      style={{
        paddingTop: "env(safe-area-inset-top)",
      }}
    >
      <div
        className={classNames(
          "sm:py-2 mx-auto flex items-center justify-center h-14 lg:h-16 gap-2 max-w-7xl",
          isTransparent && top
            ? ""
            : "border-b dark:border-gray-700 border-gray-300"
        )}
        style={{
          maxHeight: `${MAIN_NAVIGATION_HEIGHT}px`,
        }}
      >
        <div className="flex items-center justify-between w-full max-w-7xl md:px-4 xl:px-0">
          {children}
        </div>
      </div>
    </nav>
  );
};

export default NavWrapper;
