import infinity from "icons/solid/infinity.svg";
import thumbsup from "icons/solid/thumbs-up.svg";
import lists from "icons/solid/rectangle-history.svg";
import wizardhat from "icons/solid/hat-wizard.svg";
import moods from "icons/solid/comment-smile.svg";
import MenuItem from "../MenuItem";

export default function BrowseMenu() {
  return (
    <div className="flex flex-col gap-0 py-2 justify-between">
      <MenuItem
        url="/prompts"
        variant="blue"
        icon={thumbsup}
        text="Prompts"
        description="Vote for your favorites"
      />
      <MenuItem
        url="/lists"
        variant="blue"
        icon={lists}
        text="Lists"
        description="Curated by our readers"
      />
      <MenuItem
        url="/browse/tags/genre"
        variant="blue"
        icon={wizardhat}
        text="Genres"
        description="Browse by Genre"
      />
      <MenuItem
        url="/browse/tags/mood"
        variant="blue"
        icon={moods}
        text="Moods"
        description="Browse by Mood"
      />
      <MenuItem
        url="/search"
        variant="blue"
        icon={infinity}
        text="All Books"
        description="Browse all books"
      />
    </div>
  );
}
