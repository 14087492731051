import Link from "next/link";
import LogoSymbol from "./LogoSymbol";

interface Props {
  url?: string;
  className?: string;
}

export default function LogoLink({ url = "/", className = "" }: Props) {
  return (
    <Link href={url} className={`${className} rounded pr-2 md:w-64`}>
      <div className="group flex flex-nowrap flex-row items-center hover:opacity-90 transition-all">
        <LogoSymbol className="group-hover:rotate-12 transition-all duration-300" />
        <span className="font-extrabold tracking-tight text-lg lg:text-xl dark:group-hover:text-white group-hover:text-gray-600">
          Hardcover
        </span>
      </div>
    </Link>
  );
}
