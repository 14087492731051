"use client";

/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from "react";
import { UserType } from "types";
import useCurrentUser from "hooks/fragments/useCurrentUser";
import { useSelector } from "react-redux";
import {
  getIsLoggedIn,
  getIsUserLoaded,
} from "store/currentUser/currentUserSelector";
import isUndefined from "lodash/isUndefined";
import UserSpecificMenu from "./UserSpecificMenu";

export default function UserSpecificMenuWrapper({ user }: { user?: UserType }) {
  const isUserLoaded = useSelector(getIsUserLoaded);

  // Logged in
  const [isLoggedIn, setIsLoggedIn] = useState(user?.id > 0 || false);
  const activeIsLoggedIn = useSelector(getIsLoggedIn);
  useEffect(() => {
    if (
      isUserLoaded &&
      !isUndefined(activeIsLoggedIn) &&
      activeIsLoggedIn !== isLoggedIn
    ) {
      setIsLoggedIn(activeIsLoggedIn);
    }
  }, [isUserLoaded, activeIsLoggedIn]);

  // Onboarded
  const [isOnboarded, setIsOnboarded] = useState(user?.onboarded || false);
  const activeIsOnboarded = useCurrentUser((u) => u?.onboarded);
  useEffect(() => {
    if (
      isUserLoaded &&
      !isUndefined(activeIsOnboarded) &&
      activeIsOnboarded !== isOnboarded
    ) {
      setIsOnboarded(activeIsOnboarded);
    }
  }, [isUserLoaded, activeIsOnboarded]);

  // Avatar
  const [cachedImage, setCachedImage] = useState(user?.cachedImage);
  const activeCachedImage = useCurrentUser((u) => u?.cachedImage);
  useEffect(() => {
    if (
      isUserLoaded &&
      !isUndefined(activeCachedImage) &&
      activeCachedImage?.url !== cachedImage?.url
    ) {
      setCachedImage(activeCachedImage);
    }
  }, [isUserLoaded, activeCachedImage]);

  // Username
  const [username, setUsername] = useState(user?.username);
  const activeUsername = useCurrentUser((u) => u?.username);
  useEffect(() => {
    if (
      isUserLoaded &&
      !isUndefined(activeUsername) &&
      activeUsername !== username
    ) {
      setUsername(activeUsername);
    }
  }, [isUserLoaded, activeUsername]);

  // Pro
  const [pro, setPro] = useState(user?.pro || false);
  const activePro = useCurrentUser((u) => u?.pro);
  useEffect(() => {
    if (isUserLoaded && !isUndefined(activePro) && activePro !== pro) {
      setPro(activePro);
    }
  }, [isUserLoaded, activePro]);

  return (
    <UserSpecificMenu
      pro={pro}
      isLoggedIn={isLoggedIn}
      isOnboarded={isOnboarded}
      username={username}
      image={cachedImage}
    />
  );
}
