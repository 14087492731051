"use client";

import { useSelector } from "react-redux";
import { getIsUserLoaded } from "store/currentUser/currentUserSelector";
import useCurrentUser from "hooks/fragments/useCurrentUser";
import useMount from "hooks/useMount";
import { ReactElement } from "react";
import { UserType } from "types";

export default function OnboardHider({
  user,
  children,
}: {
  user?: UserType;
  children: ReactElement;
}) {
  const mounted = useMount();
  const userLoaded = useSelector(getIsUserLoaded);
  const { isLoggedIn, onboarded } = useCurrentUser((u) => {
    return {
      onboarded: u.onboarded || false,
      isLoggedIn: u?.id > 0,
    };
  });

  // SSR case, show children based on props
  if (!mounted) {
    if (!user) {
      return children;
    }
    return user.onboarded ? children : <></>;
  }

  if (!mounted || !userLoaded || !isLoggedIn) {
    return children;
  }

  return userLoaded && !onboarded ? <></> : children;
}
