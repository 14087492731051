"use client";

import Icon from "hardcover-ui/components/Icon";
import Button from "hardcover-ui/components/Button";
import lamp from "icons/solid/lamp-desk.svg";
import Brightness from "components/svgs/icons/Brightness";
import Text from "hardcover-ui/components/Text";
import { useTheme } from "features/ThemeManager";
import { MenuClass } from "./UserMenuLink";

export default function MenuThemeChanger() {
  const { toggleTheme } = useTheme();

  return (
    <Button onClick={() => toggleTheme()} className={`relative ${MenuClass}`}>
      <Brightness
        style={{ top: "12px", left: "14px", rotate: "-219deg" }}
        className="absolute dark:hidden h-3 w-3 fill-yellow-500"
      />
      <Icon
        icon={lamp}
        className="text-gray-400 group-hover/link:text-indigo-300"
      />
      <Text>Reading light</Text>
    </Button>
  );
}
