/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";
import {
  avatarTheme,
  AvatarThemeProps,
} from "hardcover-ui/theme/components/avatar";
import { ImageType } from "types";
import classNames from "lib/classNames";
import {
  AUTHOR_AVATAR_URL,
  AVATAR_URL,
  enlargeUrl,
  normalizeImageUrl,
} from "lib/imageTools";
import _ from "lodash";

type Props = {
  image: ImageType;
  subject?: AvatarThemeProps["subject"];
  variant?: AvatarThemeProps["variant"];
  size?: AvatarThemeProps["size"];
  className?: string;
  inline?: boolean;
  alt: string;
  lazy?: boolean;
} & Partial<HTMLAttributes<HTMLDivElement>>;

function calculateSourceUrl(subject: AvatarThemeProps["subject"], url: string) {
  if (url) {
    return url;
  }
  return subject === "user" ? AVATAR_URL : AUTHOR_AVATAR_URL;
}

function urlFor(
  subject: AvatarThemeProps["subject"],
  url: string,
  dimension: number
) {
  // Use larger images than expected for mobile
  const url1Width = Math.round(dimension);
  const url2Width = Math.round(dimension * 1.5);

  const sourceUrl = normalizeImageUrl(calculateSourceUrl(subject, url));

  const url2x = enlargeUrl({
    url: sourceUrl,
    width: url2Width,
    height: Math.round(dimension * 1.5),
  });
  const url1x = enlargeUrl({
    url: sourceUrl,
    width: url1Width,
    height: Math.round(dimension),
  });

  return {
    url1x,
    url1Width,
    url2x,
    url2Width,
  };
}

const Avatar = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      image,
      subject = "user",
      variant = "bordered",
      size = "md",
      lazy = true,
      inline = false,
      alt = "",
      className,
      ...rest
    },
    ref
  ) => {
    const finalClassName = classNames(
      avatarTheme.variant[variant],
      avatarTheme.size?.[size],
      inline ? "inline-block" : "",
      className
    );

    const dimension = avatarTheme.dimensions[size];
    const { url1x, url1Width, url2x, url2Width } = urlFor(
      subject,
      image?.url || AVATAR_URL,
      dimension
    );
    const timmedAlt = alt ? alt.trim() : "";
    const finalAlt = timmedAlt.length === 0 ? "User Avatar" : timmedAlt;

    const props = {
      ref,
      className: finalClassName,
      style: { width: `${dimension}px`, height: `${dimension}px` },
      ...rest,
    };

    return (
      <div {...props}>
        <img
          src={url1x}
          srcSet={`${url1x} ${url1Width}w, ${url2x} ${url2Width}w`}
          alt={finalAlt}
          width={dimension}
          height={dimension}
          loading={lazy ? "lazy" : "eager"}
          className="avatar cursor-pointer object-cover bg-cover w-full h-full hover:opacity-80 group-hover:opacity-80  transition-all"
        />
      </div>
    );
  }
);

export default Avatar;
