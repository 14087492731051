"use client";

import { ReactElement } from "react";
import Icon from "hardcover-ui/components/Icon";
import faLampDeskDuotone from "icons/duotone/lamp-desk.svg";
import Button from "hardcover-ui/components/Button";
import Brightness from "components/svgs/icons/Brightness";
import useTheme from "features/ThemeManager/hooks/useTheme";

export default function ThemeChanger({
  children,
}: {
  children?: ReactElement;
}) {
  const { theme, toggleTheme } = useTheme();

  // Basic children button
  if (children) {
    return (
      <button type="button" onClick={toggleTheme} className="block w-full">
        {children}
      </button>
    );
  }

  return (
    <Button
      variant="ghost"
      size="md"
      onClick={toggleTheme}
      className="relative"
    >
      <span className="sr-only">Use setting</span>
      {theme === "light" ? (
        <Brightness
          style={{ top: "12px", left: "14px", rotate: "-136deg" }}
          className="absolute dark:hidden h-3 w-3 fill-yellow-500"
        />
      ) : (
        false
      )}
      <Icon
        icon={faLampDeskDuotone}
        size="lg"
        className="relative z-1 h-4 w-6 transform -scale-x-100"
      />
    </Button>
  );
}
