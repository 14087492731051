"use client";

import { setCookie } from "cookies-next";
import { useDispatch, useSelector } from "react-redux";
import { getTheme } from "store/ui/uiSelector";
import { uiActions } from "store/ui/uiSlice";
import { useEffect, useState } from "react";
import { ThemeType } from "../types";

function disableTransitionsTemporarily() {
  document.documentElement.classList.add("[&_*]:!transition-none");
  window.setTimeout(() => {
    document.documentElement.classList.remove("[&_*]:!transition-none");
  }, 0);
}

interface UseThemeType {
  theme: ThemeType;
  setTheme: (newTheme: ThemeType) => void;
  toggleTheme: () => void;
}

const useTheme = (): UseThemeType => {
  const [initialTheme, setInitialTheme] = useState<ThemeType>("dark");
  const theme = useSelector(getTheme);
  const dispatch = useDispatch();

  useEffect(() => {
    setInitialTheme(
      document.documentElement.classList.contains("dark") ? "dark" : "light"
    );
  }, []);

  function toggleTheme() {
    disableTransitionsTemporarily();
    const isDarkMode = document.documentElement.classList.toggle("dark");
    const newTheme = isDarkMode ? "dark" : "light";
    setCookie("theme", newTheme);
    dispatch(uiActions.setTheme(newTheme));
  }

  function setTheme(newTheme: ThemeType) {
    disableTransitionsTemporarily();
    if (newTheme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    setCookie("theme", newTheme);
    dispatch(uiActions.setTheme(newTheme));
  }

  return {
    theme: theme || initialTheme,
    setTheme,
    toggleTheme,
  };
};

export default useTheme;
