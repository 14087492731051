import Button from "hardcover-ui/components/Button";
import Text from "hardcover-ui/components/Text";
import Icon from "hardcover-ui/components/Icon";
import classNames from "lib/classNames";

interface Props {
  url: string;
  icon: any;
  text: string;
  description: string;
  variant: "blue" | "green";
}

// text-blue-500 text-green-500 bg-green-500/40 bg-blue-500/40
export default function MenuItem({
  url,
  icon,
  text,
  description,
  variant,
}: Props) {
  return (
    <Button
      className="group gap-2 p-1 mx-2 rounded-lg transition-colors flex-grow cursor-pointer"
      as={{
        element: "link",
        props: {
          href: url,
        },
      }}
    >
      <div
        className={classNames(
          "rounded-md bg-gray-100 dark:bg-gray-800 dark:group-hover:text-gray-800 p-1 h-10 w-10 place-content-center grid",
          variant === "green"
            ? "group-hover:bg-green-400 text-green-500 group-hover:text-green-700"
            : "group-hover:bg-blue-400 text-blue-500 group-hover:text-blue-700"
        )}
      >
        <Icon icon={icon} size="lg" />
      </div>
      <div className="flex flex-col items-start">
        <Text className="font-bold">{text}</Text>
        <Text
          variant="secondary"
          size="sm"
          className="group-hover:text-gray-900 dark:group-hover:text-white"
        >
          {description}
        </Text>
      </div>
    </Button>
  );
}
