import Text from "hardcover-ui/components/Text";
import Icon from "hardcover-ui/components/Icon";
import React from "react";
import { signOut } from "next-auth/react";
import { toast } from "sonner";
import { useRouter } from "next/navigation";
import { useCurrentSession } from "hooks/useCurrentSession";
import signout from "icons/solid/right-from-bracket.svg";
import { MenuClass } from "./UserMenuLink";

export default function MenuSignOut() {
  const { resetSession } = useCurrentSession();
  const router = useRouter();

  return (
    <button
      type="button"
      className={MenuClass}
      onClick={(e) => {
        e.preventDefault();
        signOut({ redirect: false, callbackUrl: "/" }).then(() => {
          toast.success("You have been logged out.");
          resetSession().then(() => {
            router.push("/");
          });
        });
      }}
    >
      <Icon
        icon={signout}
        className="text-gray-400 group-hover/link:text-indigo-300"
      />
      <Text>Sign Out</Text>
    </button>
  );
}
