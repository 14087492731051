"use client";

import { Suspense, lazy, useState } from "react";
import MobileSubscriptionModalInactive from "./MobileSubscriptionModalInactive";

const MobileSubscriptionModalActive = lazy(
  () => import("./MobileSubscriptionModalActive")
);

export default function ExploreLink() {
  const [clicked, setClicked] = useState(false);

  // Don't load Headless UI and the entire dropdown component unless it's clicked on
  if (clicked) {
    return (
      <Suspense fallback={<MobileSubscriptionModalInactive loading />}>
        <MobileSubscriptionModalActive />
      </Suspense>
    );
  }

  return <MobileSubscriptionModalInactive onClick={() => setClicked(true)} />;
}
