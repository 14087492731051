"use client";

import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

// This custom hook let us know whether current path is
// initially opened by user from link or external source
export const useIsCurrentPageInitial = () => {
  const pathname = usePathname();
  const [pathStack, setPathStack] = useState([]);

  useEffect(() => {
    if (pathStack.length === 0) {
      // No other paths saved, this is the first load
      setPathStack([pathname]);
    } else if (pathStack[pathStack.length - 2] === pathname) {
      // Went back, remove last path
      const newPath = [...pathStack];
      newPath.splice(pathStack.length - 1, 1);
      setPathStack(newPath);
    } else {
      // Went forward, add a new path
      setPathStack([...pathStack, pathname]);
    }
  }, [pathname]);

  return pathStack.length === 0 || pathStack.length === 1;
};
