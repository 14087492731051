"use client";

import { Suspense, lazy, useState } from "react";
import LogoutLinkInactive from "./LogoutLinkInactive";

const LogoutLinkActive = lazy(() => import("./LogoutLinkActive"));

export default function LogoutLink() {
  const [clicked, setClicked] = useState(false);

  // Don't load Headless UI and the entire dropdown component unless it's clicked on
  if (clicked) {
    return (
      <Suspense fallback={<LogoutLinkInactive loading />}>
        <LogoutLinkActive />
      </Suspense>
    );
  }

  return <LogoutLinkInactive onClick={() => setClicked(true)} />;
}
