"use client";

import { useCallback } from "react";
import Icon from "hardcover-ui/components/Icon";
import faChevronLeft from "icons/solid/chevron-left.svg";
import Button from "hardcover-ui/components/Button";
import { useRouter } from "next/navigation";

export default function BackLink({ className = "" }: { className?: string }) {
  const router = useRouter();
  const handleGoBack = useCallback(() => router.back(), []);

  return (
    <Button
      onClick={handleGoBack}
      className={`${className} dark:text-gray-400 text-stone-800 font-medium`}
    >
      <Icon icon={faChevronLeft} /> <span>Back</span>
    </Button>
  );
}
