"use client";

import React, { useState, useEffect } from "react";
import HardcoverLogo from "components/svgs/icons/HardcoverLogo";

// Define a function to check if the current date is within a given range
const isDateInRange = (startDate, endDate) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const start = new Date(`${startDate}, ${currentYear}`);
  const end = new Date(`${endDate}, ${currentYear}`);
  // Ensure that the end date is set to the end of the day (23:59:59)
  end.setHours(23, 59, 59, 999);
  return now >= start && now <= end;
};

const logos = {
  Default: {
    component: HardcoverLogo,
    className: "w-9",
  },
  Orange: { path: "HardcoverLogoOrange", className: "w-9" },
  GuyFawkes: {
    path: "HardcoverLogoGuyFawkes",
    className: "w-9 mr-2",
    startDate: "November 5",
    endDate: "November 5",
  },
  Thanksgiving: {
    path: "HardcoverLogoThanksgiving",
    className: "w-9 mr-2",
    startDate: "November 22",
    endDate: "November 24",
  },
  Christmas: {
    path: "HardcoverLogoChristmas",
    className: "w-10",
    startDate: "December 23",
    endDate: "December 27",
  },
  Valentines: {
    path: "HardcoverLogoValentines",
    className: "w-10",
    startDate: "February 13",
    endDate: "February 15",
  },
};

const selectVariant = () => {
  return (
    Object.keys(logos).find((key) => {
      const logo = logos[key];
      return (
        logo.startDate &&
        logo.endDate &&
        isDateInRange(logo.startDate, logo.endDate)
      );
    }) || "Default"
  );
};

interface Props {
  className?: string;
}

const LogoSymbol = ({ className = "" }: Props) => {
  const [SelectedLogo, setSelectedLogo] = useState(() => HardcoverLogo);
  const variant = selectVariant();
  const { path: logoPath, className: defaultClassName } = logos[variant];

  useEffect(() => {
    if (logoPath) {
      import(`components/svgs/icons/${logoPath}`)
        .then((module) => {
          setSelectedLogo(() => module.default);
        })
        .catch(() => {
          // Fallback to default logo in case of error
          setSelectedLogo(() => HardcoverLogo);
        });
    } else {
      setSelectedLogo(() => HardcoverLogo);
    }
  }, [logoPath]);

  const combinedClassName = `${defaultClassName || "w-9"} ${className}`.trim();

  return <SelectedLogo className={combinedClassName} />;
};

export default LogoSymbol;
