/* eslint-disable react/jsx-props-no-spreading */

"use client";

import { useDispatch } from "react-redux";
import { searchActions } from "store/search/searchSlice";
import { ReactElement } from "react";

export default function QuickSearchButton({
  children,
  className,
  ...props
}: {
  className?: string;
  children: ReactElement;
}): JSX.Element {
  const dispatch = useDispatch();

  const toggleQuickSearch = () => {
    dispatch(searchActions.toggleSearchVisible());
  };

  return (
    <button
      type="button"
      className={className}
      onClick={() => toggleQuickSearch()}
      {...props}
    >
      {children}
    </button>
  );
}
