import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getState = (state: RootState) => state;

export const getUiChannel = createSelector(getState, (state) => state.ui);

export const getIsLoginVisible = createSelector(
  getUiChannel,
  ({ loginVisible }) => {
    return loginVisible;
  }
);

export const getTheme = createSelector(getUiChannel, ({ theme }) => {
  return theme;
});
