/* eslint-disable @typescript-eslint/no-unused-vars */
import { ImageType } from "types";
import wanttoread from "icons/solid/bookmark.svg";
import reading from "icons/solid/book-open-cover.svg";
import read from "icons/solid/book-sparkles.svg";
import lists from "icons/solid/rectangle-history.svg";
import owned from "icons/solid/backpack.svg";
import goals from "icons/solid/bullseye-arrow.svg";
import stats from "icons/solid/chart-mixed.svg";
import reviews from "icons/solid/message-heart.svg";
import prompts from "icons/solid/comment-dots.svg";
import settings from "icons/solid/gear.svg";
import NotificationIndicator from "features/Nav/components/Nav/parts/NotificationIndicator";
import CurrentUserAvatar from "components/shared/CurrentUserAvatar";
import Text from "hardcover-ui/components/Text";
import Link from "next/link";
import UserMenuItem from "./parts/UserMenuLink";
import MenuThemeChanger from "./parts/MenuThemeChanger";
import MenuSignOut from "./parts/MenuSignOut";
import DropdownIndicator from "../DropdownIndicator";
import MenuNotifications from "./parts/MenuNotifications";

export default function UserMenu({
  pro,
  image,
  username,
}: {
  pro: boolean;
  image: ImageType;
  username: string;
}) {
  return (
    <div className="group/usermenu relative -m-2 p-1">
      <Link
        href={`/@${username}`}
        className="bg-white  rounded-full flex mt-1 hover:outline-none hover:ring-2 hover:ring-offset hover:ring-offset-gray-100 dark:hover:ring-offset-gray-800 hover:ring-white"
      >
        <span className="sr-only">Open user menu</span>
        <NotificationIndicator />
        <CurrentUserAvatar size="md" image={image} />
      </Link>

      <div className="absolute top-8 -mt-px -right-4 z-20 hidden group-hover/usermenu:block pointer-events-none">
        <DropdownIndicator className="w-20" />
      </div>

      <div className="-m-3 p-2 hidden group-hover/usermenu:block absolute top-16 right-1">
        <div className="-mt-1 min-w-max divide-y divide-secondary ring-2 dark:ring-gray-700 dark:bg-gray-900 ring-gray-200 bg-background rounded-lg shadow-lg overflow-hidden">
          <div className="min-w-full flex flex-col p-2">
            <MenuNotifications />
          </div>
          <div className="min-w-full flex flex-col p-2">
            <Text variant="secondary" size="sm" as="p">
              Books
            </Text>
            <UserMenuItem
              icon={wanttoread}
              url={`/@${username}/books/want-to-read`}
              text="Want to read"
            />
            <UserMenuItem
              icon={reading}
              url={`/@${username}/books/currently-reading`}
              text="Currently reading"
            />
            <UserMenuItem
              icon={read}
              url={`/@${username}/books/read`}
              text="Read"
            />
            <UserMenuItem
              icon={owned}
              url={`/@${username}/lists/owned`}
              text="Owned"
            />
          </div>
          <div className="min-w-full flex flex-col p-2">
            <UserMenuItem
              icon={stats}
              url={`/@${username}/stats`}
              text="Stats"
            />
            <UserMenuItem
              icon={reviews}
              url={`/@${username}/reviews`}
              text="Reviews"
            />
            <UserMenuItem
              icon={lists}
              url={`/@${username}/lists`}
              text="Lists"
            />
            <UserMenuItem
              icon={prompts}
              url={`/@${username}/prompts/answered`}
              text="Prompts"
            />
            <UserMenuItem
              icon={goals}
              url={`/@${username}/goals`}
              text="Goals"
            />
          </div>
          <div className="min-w-full flex flex-col p-2">
            <MenuThemeChanger />
            <UserMenuItem icon={settings} url="/account" text="Settings" />
          </div>

          <div className="min-w-full flex flex-col p-2">
            <MenuSignOut />
          </div>
        </div>
      </div>
    </div>
  );
}
