/* eslint-disable react/jsx-props-no-spreading */

const JulesRocket = (props) => (
  <svg
    viewBox="0 0 289 218"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.7051 139.106L87.3706 160.313C87.6715 160.429 88.0131 160.323 88.1943 160.056L107.245 131.983C107.716 131.289 107.377 130.336 106.573 130.095L17.4979 103.432C17.388 103.399 17.2712 103.455 17.2288 103.562C11.6101 117.652 18.5627 133.62 32.7051 139.106Z"
      fill="#0F0E26"
    />
    <g filter="url(#filter0_f_4558_14242)">
      <path
        d="M22.9929 99.5584L37.9438 90.4608L32.9515 100.578L39.4611 106.141L66.4046 87.1078L52.5314 101.212L57.1235 107.609C55.6124 108.442 54.2846 109.164 53.1633 109.762C52.0594 110.35 51.1839 110.802 50.5433 111.115C50.4976 111.138 50.4543 111.159 50.4132 111.179L22.2187 107.736L22.9929 99.5584Z"
        stroke="#6366F1"
        strokeWidth={8.8341}
      />
    </g>
    <g filter="url(#filter1_f_4558_14242)">
      <path
        d="M210.561 71.6333C208.859 89.6059 196.146 101.337 183.891 100.177C171.637 99.0165 161.351 85.1079 163.052 67.1353C164.754 49.1628 177.468 37.4318 189.722 38.592C201.976 39.7522 212.263 53.6608 210.561 71.6333Z"
        stroke="#6366F1"
        strokeWidth={8.8341}
      />
    </g>
    <g filter="url(#filter2_f_4558_14242)">
      <path
        d="M233.229 57.2092C231.719 73.1564 220.263 83.4995 209.238 82.4557C198.214 81.4119 188.902 69.1026 190.412 53.1554C191.922 37.2082 203.378 26.8652 214.403 27.909C225.427 28.9528 234.739 41.262 233.229 57.2092Z"
        stroke="#6366F1"
        strokeWidth={8.8341}
      />
    </g>
    <g filter="url(#filter3_f_4558_14242)">
      <path
        d="M256.61 46.24C255.429 58.7091 246.493 67.4733 237.226 66.5959C227.958 65.7185 220.825 55.4329 222.006 42.9638C223.186 30.4947 232.123 21.7305 241.39 22.6079C250.658 23.4853 257.791 33.771 256.61 46.24Z"
        stroke="#6366F1"
        strokeWidth={2.9447}
      />
    </g>
    <path
      d="M155.239 52.7845L61.0143 104.222L80.9065 119.124L170.054 127.564L193.507 107.81C201.097 101.416 206.289 91.9802 204.263 82.2653C197.947 51.9844 168.739 45.8432 155.239 52.7845Z"
      fill="#0F0E26"
    />
    <path
      d="M109.648 78.0567L61.7375 102.835L81.6297 117.737L129.412 113.066L156.575 94.9263C152.9 64.9907 125.199 70.061 109.648 78.0567Z"
      fill="#111827"
    />
    <path
      d="M82.659 106.863C82.659 106.863 91.8126 108.177 107.341 96.1808"
      stroke="#6366F1"
      strokeWidth={6.74472}
      strokeLinecap="round"
    />
    <path
      d="M138.705 89.9753L144.235 95.1744L114.08 127.247L103.352 127.578L138.705 89.9753Z"
      fill="#D1D5DB"
    />
    <path
      d="M138.705 89.977L133.175 84.7779L103.021 116.851L103.351 127.58L138.705 89.977Z"
      fill="#9CA3AF"
    />
    <rect
      x={147.553}
      y={98.2949}
      width={24.2883}
      height={5.56607}
      rx={0.421545}
      transform="rotate(-136.766 147.553 98.2949)"
      fill="#312E81"
    />
    <rect
      x={99.2219}
      y={117.576}
      width={24.2883}
      height={5.56607}
      rx={0.421545}
      transform="rotate(-166.012 99.2219 117.576)"
      fill="#312E81"
    />
    <path
      d="M144.059 89.4512L138.898 84.5987L148.949 73.9077C150.289 72.4824 152.531 72.4133 153.956 73.7533C155.382 75.0933 155.451 77.3349 154.111 78.7601L144.059 89.4512Z"
      fill="#312E81"
    />
    <ellipse
      cx={118.178}
      cy={75.2573}
      rx={12.9032}
      ry={12.3972}
      transform="rotate(31.746 118.178 75.2573)"
      fill="#6366F1"
    />
    <path
      d="M110.597 72.6171C110.382 73.2527 110.555 73.9904 111.077 74.6679C111.599 75.3453 112.428 75.9071 113.382 76.2295C114.335 76.552 115.335 76.6087 116.161 76.3872C116.987 76.1658 117.572 75.6843 117.787 75.0486L114.192 73.8328L110.597 72.6171Z"
      fill="white"
    />
    <ellipse
      cx={108.279}
      cy={71.1935}
      rx={1.94785}
      ry={2.27703}
      transform="rotate(12.5001 108.279 71.1935)"
      fill="#111827"
    />
    <ellipse
      cx={121.172}
      cy={75.665}
      rx={2.02403}
      ry={2.27703}
      transform="rotate(12.5001 121.172 75.665)"
      fill="#111827"
    />
    <path
      d="M122.587 91.5829C117.256 93.6502 112.4 92.1566 109.551 90.494C108.702 89.9984 107.532 90.3135 107.189 91.235L88.4257 141.674C88.1004 142.549 88.6956 143.493 89.6248 143.577L134.989 147.666C135.943 147.752 136.705 146.885 136.498 145.95L124.675 92.6221C124.473 91.7113 123.457 91.2456 122.587 91.5829Z"
      fill="#4F46E5"
    />
    <path
      d="M111.846 92.1851C111.846 92.1851 104.082 114.725 99.7096 143.511"
      stroke="#312E81"
      strokeWidth={0.84309}
      strokeLinecap="round"
    />
    <ellipse
      cx={1.51769}
      cy={1.73919}
      rx={1.51769}
      ry={1.73919}
      transform="matrix(0.980417 0.196934 -0.200873 0.979617 112.27 99.0605)"
      fill="#312E81"
    />
    <ellipse
      cx={1.51769}
      cy={1.73919}
      rx={1.51769}
      ry={1.73919}
      transform="matrix(0.980417 0.196934 -0.200873 0.979617 110.045 109.279)"
      fill="#312E81"
    />
    <path
      d="M116.244 49.9312L110.591 64.6595C110.129 65.8647 111.482 66.9559 112.562 66.2484L123.526 59.0654C124.657 58.3244 126.019 58.021 127.358 58.212L135.353 59.3525C139.403 59.9303 143.154 57.1107 143.724 53.059C144.303 48.948 141.386 45.1657 137.263 44.68L126.946 43.4647C122.32 42.9197 117.913 45.5823 116.244 49.9312Z"
      fill="url(#paint0_linear_4558_14242)"
    />
    <path
      d="M132.302 54.9042C132.913 54.5364 134.448 54.0928 135.7 55.2612"
      stroke="#3730A3"
      strokeWidth={0.740237}
      strokeLinecap="round"
    />
    <path
      d="M125.982 49.6601C126.593 49.2922 128.128 48.8486 129.38 50.0171"
      stroke="#3730A3"
      strokeWidth={0.740237}
      strokeLinecap="round"
    />
    <path
      d="M121.482 54.3417C122.093 53.9739 123.628 53.5303 124.88 54.6987"
      stroke="#3730A3"
      strokeWidth={0.740237}
      strokeLinecap="round"
    />
    <path
      d="M134.148 48.2284C134.759 47.8606 136.295 47.417 137.546 48.5854"
      stroke="#3730A3"
      strokeWidth={0.740237}
      strokeLinecap="round"
    />
    <path
      d="M126.734 83.7988C126.635 85.1394 128.791 85.8698 129.541 84.7537C132.067 80.9905 135.125 76.3494 135.242 75.1275C135.431 73.1587 135.23 71.1721 134.65 69.281C134.071 67.39 133.125 65.6316 131.866 64.1062C130.607 62.5808 129.06 61.3183 127.313 60.3908C125.566 59.4632 123.654 58.8889 121.685 58.7004C119.716 58.512 117.73 58.7132 115.839 59.2926C113.948 59.8719 112.219 60.7516 110.205 62.2754C107.497 64.3249 106.909 66.3876 106.126 68.0584C106.005 68.3167 106.129 68.6288 106.398 68.7217L126.409 75.6173C126.942 75.801 127.284 76.3207 127.243 76.883L126.734 83.7988Z"
      fill="url(#paint1_linear_4558_14242)"
    />
    <path
      d="M139.485 60.2969L130.93 73.6549C130.029 75.0628 131.279 76.8579 132.912 76.5L143.711 74.1331C145.383 73.7666 147.133 74.0794 148.575 75.0028L152.809 77.7143C156.917 80.3456 162.381 79.1481 165.012 75.0395C167.644 70.9309 166.446 65.4672 162.337 62.8358L153.557 57.2127C148.82 54.1785 142.52 55.5593 139.485 60.2969Z"
      fill="#6366F1"
    />
    <path
      d="M145.941 61.7536C146.614 61.5166 148.207 61.3919 149.197 62.7888"
      stroke="#3730A3"
      strokeWidth={0.740237}
      strokeLinecap="round"
    />
    <path
      d="M156.622 65.7458C157.295 65.5088 158.888 65.3841 159.878 66.781"
      stroke="#3730A3"
      strokeWidth={0.740237}
      strokeLinecap="round"
    />
    <path
      d="M149.1 68.7341C149.773 68.4971 151.366 68.3724 152.356 69.7693"
      stroke="#3730A3"
      strokeWidth={0.740237}
      strokeLinecap="round"
    />
    <path
      d="M155.465 73.1481C156.138 72.9112 157.731 72.7864 158.721 74.1833"
      stroke="#3730A3"
      strokeWidth={0.740237}
      strokeLinecap="round"
    />
    <path
      d="M138.839 69.0544C139.512 68.8174 141.105 68.6927 142.095 70.0896"
      stroke="#3730A3"
      strokeWidth={0.740237}
      strokeLinecap="round"
    />
    <path
      d="M100.564 111.517C100.564 111.517 115.393 111.146 120.22 97.4007"
      stroke="#6366F1"
      strokeWidth={6.74472}
      strokeLinecap="round"
    />
    <path
      d="M59.8233 103.538L31.6708 126.16C21.3746 134.434 14.4019 146.139 12.0289 159.133L10.1942 169.179C8.15257 180.358 18.2921 189.937 29.3372 187.265L38.0363 185.16L61.6195 180.321C61.7545 180.293 61.8862 180.251 62.0125 180.196L112.172 158.23C112.546 158.066 112.965 158.034 113.359 158.139L134.706 163.791C150.499 167.972 167.323 165.33 181.075 156.508C181.765 156.066 181.63 155.02 180.85 154.767L150.367 144.9C148.828 144.401 148.518 142.36 149.84 141.428L171.338 126.267C172.34 125.56 172.456 124.118 171.58 123.26L151.478 103.575C150.9 103.009 150.033 102.855 149.295 103.189L129.039 112.351C128.934 112.398 128.822 112.437 128.71 112.466C90.907 122.012 69.2175 110.532 63.2792 104.015C62.4085 103.059 60.831 102.728 59.8233 103.538Z"
      fill="#0F0E26"
    />
    <path
      opacity={0.5}
      d="M85.4435 82.4411L61.7676 102.519C75.4492 118.016 104.951 117.259 117.991 114.943L125.885 98.0635C127.238 95.1689 124.702 91.7177 121.512 91.5332C110.312 90.8855 97.3618 85.7029 89.8918 81.9458C88.4382 81.2147 86.6845 81.3887 85.4435 82.4411Z"
      fill="#6366F1"
    />
    <path
      d="M76.2304 121.587L55.2849 113.254C53.5493 112.564 51.5818 112.725 50.038 113.777C41.4766 119.608 32.7297 128.075 26.4503 134.556C23.554 137.545 24.6919 142.503 28.5685 144.018L40.2816 148.597C44.9115 150.407 50.1318 149.858 54.284 147.125L77.2917 131.978C81.2697 129.36 80.6557 123.348 76.2304 121.587Z"
      fill="url(#paint2_linear_4558_14242)"
    />
    <rect
      width={23.5276}
      height={23.8474}
      rx={9.66704}
      transform="matrix(0.961538 0.27467 -0.634628 0.772818 46.3438 118.836)"
      fill="#FBBF24"
    />
    <path
      d="M53.0186 130.098C53.21 129.81 53.232 129.549 53.0846 129.315C52.9398 129.083 52.6496 128.904 52.2139 128.78C51.9075 128.692 51.6143 128.654 51.3341 128.666C51.0539 128.677 50.8035 128.73 50.5827 128.825C50.3619 128.919 50.1831 129.047 50.0463 129.21C49.9347 129.346 49.8763 129.474 49.871 129.596C49.8683 129.718 49.9055 129.834 49.9827 129.942C50.0616 130.049 50.1637 130.149 50.2891 130.242C50.4145 130.336 50.5473 130.422 50.6875 130.501L51.3225 130.871C51.5818 131.016 51.8126 131.178 52.015 131.356C52.22 131.535 52.3737 131.731 52.4762 131.944C52.5813 132.157 52.6136 132.388 52.5731 132.636C52.5326 132.884 52.3964 133.149 52.1646 133.431C51.8509 133.813 51.4532 134.115 50.9714 134.336C50.4915 134.555 49.9546 134.681 49.361 134.713C48.7717 134.744 48.1522 134.667 47.5026 134.481C46.8715 134.301 46.388 134.066 46.0522 133.776C45.7191 133.487 45.5455 133.154 45.5316 132.778C45.5203 132.403 45.6819 131.996 46.0163 131.557L47.4621 131.97C47.2967 132.203 47.2287 132.417 47.2581 132.611C47.2876 132.805 47.3946 132.975 47.5791 133.119C47.7664 133.265 48.0131 133.381 48.3194 133.468C48.639 133.56 48.9503 133.601 49.2533 133.594C49.5607 133.584 49.8398 133.531 50.0904 133.432C50.3427 133.331 50.5469 133.188 50.7029 133.002C50.8397 132.831 50.8927 132.673 50.8618 132.528C50.8327 132.381 50.7391 132.239 50.5809 132.102C50.4271 131.964 50.2294 131.823 49.9877 131.681L49.2216 131.226C48.6668 130.898 48.3104 130.538 48.1523 130.147C47.9985 129.754 48.1134 129.324 48.4968 128.858C48.8123 128.473 49.2179 128.174 49.7137 127.959C50.2122 127.745 50.7512 127.623 51.3309 127.592C51.9123 127.559 52.4868 127.623 53.0546 127.786C53.6302 127.95 54.0684 128.175 54.369 128.46C54.674 128.743 54.8338 129.063 54.8485 129.418C54.8649 129.771 54.7263 130.133 54.4327 130.502L53.0186 130.098Z"
      fill="#78350F"
    />
    <ellipse
      cx={139.332}
      cy={125.838}
      rx={4.0111}
      ry={5.30206}
      transform="rotate(5.40849 139.332 125.838)"
      fill="url(#paint3_linear_4558_14242)"
    />
    <ellipse
      cx={155.739}
      cy={112.489}
      rx={4.0111}
      ry={5.30206}
      transform="rotate(5.40849 155.739 112.489)"
      fill="url(#paint4_linear_4558_14242)"
    />
    <ellipse
      cx={170.143}
      cy={101.686}
      rx={4.0111}
      ry={5.30206}
      transform="rotate(5.40849 170.143 101.686)"
      fill="url(#paint5_linear_4558_14242)"
    />
    <g filter="url(#filter4_f_4558_14242)">
      <path
        d="M149.282 138.596L171.315 127.308L165.507 139.079L172.016 144.641L198.96 125.608L185.087 139.713L189.476 145.499C187.695 146.667 186.17 147.649 184.935 148.419C184.039 148.978 183.332 149.401 182.81 149.695L148.538 146.45L149.282 138.596Z"
        stroke="#6366F1"
        strokeWidth={8.8341}
      />
    </g>
    <path
      d="M232.794 75.8856C233.105 74.6262 234.895 74.6262 235.206 75.8856C235.317 76.3335 235.666 76.6833 236.114 76.7939C237.374 77.1048 237.374 78.8952 236.114 79.2061C235.666 79.3167 235.317 79.6665 235.206 80.1144C234.895 81.3738 233.105 81.3738 232.794 80.1144C232.683 79.6665 232.334 79.3167 231.886 79.2061C230.626 78.8952 230.626 77.1048 231.886 76.7939C232.334 76.6833 232.683 76.3335 232.794 75.8856Z"
      fill="#F9FAFB"
    />
    <path
      d="M61.7939 54.8856C62.1048 53.6262 63.8952 53.6262 64.2061 54.8856C64.3167 55.3335 64.6665 55.6833 65.1144 55.7939C66.3738 56.1048 66.3738 57.8952 65.1144 58.2061C64.6665 58.3167 64.3167 58.6665 64.2061 59.1144C63.8952 60.3738 62.1048 60.3738 61.7939 59.1144C61.6833 58.6665 61.3335 58.3167 60.8856 58.2061C59.6262 57.8952 59.6262 56.1048 60.8856 55.7939C61.3335 55.6833 61.6833 55.3335 61.7939 54.8856Z"
      fill="#F9FAFB"
    />
    <path
      d="M118.966 184.188C119.233 183.108 120.767 183.108 121.034 184.188C121.129 184.572 121.428 184.871 121.812 184.966C122.892 185.233 122.892 186.767 121.812 187.034C121.428 187.129 121.129 187.428 121.034 187.812C120.767 188.892 119.233 188.892 118.966 187.812C118.871 187.428 118.572 187.129 118.188 187.034C117.108 186.767 117.108 185.233 118.188 184.966C118.572 184.871 118.871 184.572 118.966 184.188Z"
      fill="#F9FAFB"
    />
    <defs>
      <filter
        id="filter0_f_4558_14242"
        x={2.69276}
        y={40.3234}
        width={165.708}
        height={90.1072}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={7.36175}
          result="effect1_foregroundBlur_4558_14242"
        />
      </filter>
      <filter
        id="filter1_f_4558_14242"
        x={150.603}
        y={26.2393}
        width={72.407}
        height={86.2891}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={3.92627}
          result="effect1_foregroundBlur_4558_14242"
        />
      </filter>
      <filter
        id="filter2_f_4558_14242"
        x={171.115}
        y={8.69251}
        width={81.4106}
        height={92.9802}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={7.36175}
          result="effect1_foregroundBlur_4558_14242"
        />
      </filter>
      <filter
        id="filter3_f_4558_14242"
        x={205.684}
        y={6.35072}
        width={67.2483}
        height={76.5036}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={7.36175}
          result="effect1_foregroundBlur_4558_14242"
        />
      </filter>
      <filter
        id="filter4_f_4558_14242"
        x={129.001}
        y={78.8253}
        width={159.77}
        height={90.1052}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={7.36175}
          result="effect1_foregroundBlur_4558_14242"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4558_14242"
        x1={125.531}
        y1={67.0103}
        x2={122.744}
        y2={39.9818}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F46E5" />
        <stop offset={1} stopColor="#6366F1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4558_14242"
        x1={120.236}
        y1={74.8144}
        x2={113.27}
        y2={57.5385}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3730A3" />
        <stop offset={1} stopColor="#4F46E5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4558_14242"
        x1={54.344}
        y1={111.876}
        x2={76.7675}
        y2={146.151}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#312E81" />
        <stop offset={1} stopColor="#312E81" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4558_14242"
        x1={139.332}
        y1={120.536}
        x2={145.278}
        y2={123.899}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#312E81" />
        <stop offset={1} stopColor="#312E81" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4558_14242"
        x1={155.739}
        y1={107.186}
        x2={161.684}
        y2={110.55}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#312E81" />
        <stop offset={1} stopColor="#312E81" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4558_14242"
        x1={170.143}
        y1={96.3838}
        x2={176.089}
        y2={99.7472}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#312E81" />
        <stop offset={1} stopColor="#312E81" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export default JulesRocket;
