import { useEffect, useState } from "react";
import { useVerticalScrollPosition } from "hooks/useVerticalScrollPosition";

const HIDE_NAV_BREAKPOINT_IN_PX = 20;

export const useIsNavVisible = () => {
  const { scrollPosition, direction } = useVerticalScrollPosition();
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [top, setTop] = useState(scrollPosition === 0);

  useEffect(() => {
    setTop(scrollPosition === 0);
    if (scrollPosition < HIDE_NAV_BREAKPOINT_IN_PX || direction === "toTop") {
      setIsNavVisible(true);
    } else {
      setIsNavVisible(false);
    }
  }, [scrollPosition, direction]);

  return {
    top,
    isNavVisible,
  };
};
