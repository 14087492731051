"use client";

/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from "react";
import useCurrentUser from "hooks/fragments/useCurrentUser";
import { userLoadingState } from "store/currentUser/currentUserSelector";
import { useSelector } from "react-redux";
import isUndefined from "lodash/isUndefined";

export default function NotificationIndicator({
  initialNotificationsCount = 0,
}: {
  initialNotificationsCount?: number;
}) {
  const { userLoaded } = useSelector(userLoadingState);
  const [hasNotifications, setHasNotifications] = useState(
    initialNotificationsCount > 0
  );
  const currentNotificationsCount: number = useCurrentUser(
    (u) => u.notificationsCount
  );

  useEffect(() => {
    if (userLoaded && !isUndefined(currentNotificationsCount)) {
      setHasNotifications(currentNotificationsCount > 0);
    }
  }, [userLoaded, currentNotificationsCount]);

  if (!hasNotifications) {
    return false;
  }

  return (
    <span className="absolute rounded-full bottom-0 right-0 bg-red-500 p-1" />
  );
}
