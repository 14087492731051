/* eslint-disable @typescript-eslint/no-empty-function */
import UserJoinButton from "components/join/UserJoinButton";
import { ThemeChanger } from "features/ThemeManager";
import { ImageType } from "types";
import PricingLink from "../UserMenu/PricingLink";
import UserMenu from "./UserMenu/UserMenu";
import LogoutLinkActive from "../parts/LogoutLink/LogoutLinkActive";

export default function UserSpecificMenu({
  pro,
  isLoggedIn,
  isOnboarded,
  image,
  username,
}: {
  pro: boolean;
  isLoggedIn: boolean;
  isOnboarded: boolean;
  image: ImageType;
  username: string;
}) {
  return (
    <>
      {!isLoggedIn ? (
        <>
          <ThemeChanger />
          <UserJoinButton variant="primary" tab="Login">
            <>Login</>
          </UserJoinButton>
        </>
      ) : (
        <>
          {isOnboarded ? (
            <>
              {!pro && <PricingLink />}
              <UserMenu pro={pro} username={username} image={image} />
            </>
          ) : (
            <LogoutLinkActive />
          )}
        </>
      )}
    </>
  );
}
