"use client";

import Icon from "hardcover-ui/components/Icon";
import LoadingSpinner from "hardcover-ui/components/LoadingSpinner";
import linkIcon from "icons/solid/link.svg";

export default function ShareButton({
  onClick,
  loading = false,
}: {
  onClick?: () => void;
  loading?: boolean;
}) {
  return (
    <button
      aria-label="Copy Link"
      type="button"
      className="text-indigo-500"
      onClick={onClick}
    >
      {loading ? (
        <LoadingSpinner size="md" />
      ) : (
        <Icon icon={linkIcon} size="xl" />
      )}
    </button>
  );
}
