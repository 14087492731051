"use client";

import { useState, useEffect, ReactElement } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import CountBadge from "./ui/CountBadge";

function findIfPathMatch(
  href: string,
  currentPath: string,
  greedy: boolean | string
) {
  if (!greedy) {
    return currentPath === href;
  }

  if (typeof greedy === "string") {
    return currentPath.startsWith(greedy);
  }

  return currentPath.startsWith(href);
}

type Props = {
  href: string;
  activeClassName: string;
  greedy?: boolean | string;
  className?: string;
  children: ReactElement;
  badge?: string;
  badgeClass?: string;
  pathname?: string;
};

export default function ActiveLink({
  href,
  activeClassName,
  children,
  className = "",
  greedy = false,
  badge = null,
  badgeClass = "",
  pathname: initialPathname,
  ...props
}: Props) {
  const pathName = usePathname();
  const [active, setActive] = useState(
    findIfPathMatch(href, initialPathname, greedy)
  );

  useEffect(() => {
    // Ex:
    //  href: "@hardcover/lists"
    //  currentPath: "@hardcover/lists/10-award-winning-books-by-asian-authors-you-should-read"
    const doesPathnameMatchLinkHref = findIfPathMatch(href, pathName, greedy);

    setActive(doesPathnameMatchLinkHref);
  }, [pathName, href, className, activeClassName, greedy]);

  return (
    <Link
      href={href}
      {...props}
      className={active ? activeClassName : className}
    >
      {children}

      {badge && (
        <CountBadge className={badgeClass} active={false}>
          {badge}
        </CountBadge>
      )}
    </Link>
  );
}
