"use client";

import classNames from "lib/classNames";
import {
  menuIconTheme,
  menuItemTheme,
} from "hardcover-ui/theme/components/menu";
import Button from "hardcover-ui/components/Button";
import Icon from "hardcover-ui/components/Icon";
import faSignOut from "icons/duotone/arrow-right-from-bracket.svg";
import LoadingSpinner from "hardcover-ui/components/LoadingSpinner";

interface Props {
  active?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

export default function LogoutLinkInactive({
  onClick,
  loading,
  active = false,
}: Props) {
  return (
    <Button
      variant="unstyled"
      size="md"
      className={classNames(
        active
          ? menuItemTheme.variant.primaryActive
          : menuItemTheme.variant.primary,
        "w-full rounded-md py-3 focus:outline-none group"
      )}
      onClick={onClick}
    >
      {loading ? (
        <LoadingSpinner size="md" />
      ) : (
        <Icon
          icon={faSignOut}
          size="lg"
          className={classNames(
            active
              ? `${menuIconTheme.variant.primaryActive}`
              : `${menuIconTheme.variant.primary} `
          )}
        />
      )}
      Sign out
    </Button>
  );
}
