/* eslint-disable @typescript-eslint/no-empty-function */
import { ImageType } from "types";
import UserJoinButton from "components/join/UserJoinButton";
import MobileSubscriptionModal from "./MobileSubscriptionModal";
import MobileUserNavSidepanel from "./MobileUserNavSidepanel";
import LogoutLink from "../parts/LogoutLink";
import ShareButton from "./ShareButton/ShareButton";

export default function MobileNavLinks({
  pro,
  isLoggedIn,
  isOnboarded,
  image,
}: {
  pro: boolean;
  isLoggedIn: boolean;
  isOnboarded: boolean;
  image: ImageType;
}) {
  return (
    <div className="lg:hidden flex space-x-4 justify-end items-center pr-1">
      <ShareButton />
      {!isLoggedIn ? (
        <>
          <UserJoinButton variant="primary" tab="Login">
            <>Login</>
          </UserJoinButton>
        </>
      ) : (
        <>
          {isOnboarded ? (
            <>
              {!pro && <MobileSubscriptionModal />}
              <MobileUserNavSidepanel image={image} />
            </>
          ) : (
            <LogoutLink />
          )}
        </>
      )}
    </div>
  );
}
