import { usePathname } from "next/navigation";

const listPathRegex = new RegExp(/\/@.*\/lists\/.*/);
const tagPathRegex = new RegExp(/\/browse\/tags\/.*\/.*/);
export default function useTransparentNav() {
  const path = usePathname();

  if (path.startsWith("/books")) {
    return true;
  }

  return listPathRegex.test(path) || tagPathRegex.test(path);
}
