export type MenuItemsThemeProps = {
  variant: "primary";
};

export const menuItemsTheme = {
  variant: {
    primary: "bg-white dark:bg-gray-900 divide-gray-300 dark:divide-gray-500",
  },
};

export type MenuItemThemeProps = {
  variant: "primary" | "primaryActive";
};

const menuItemPrimaryTheme = "dark:text-gray-100";
export const menuItemTheme = {
  variant: {
    primary: `${menuItemPrimaryTheme} hover:bg-indigo-500 hover:text-white text-gray-600`,
    primaryActive: `${menuItemPrimaryTheme} bg-indigo-500 text-white`,
  },
};

const menuIconPrimaryTheme = "group-hover:text-white";
export const menuIconTheme = {
  variant: {
    primary: `${menuIconPrimaryTheme} text-indigo-500 dark:text-indigo-300`,
    primaryActive: `${menuIconPrimaryTheme} text-white`,
  },
};
