"use client";

import { useEffect, useState } from "react";
import faSearch from "icons/solid/magnifying-glass.svg";
import Icon from "hardcover-ui/components/Icon";
import isHotkey from "is-hotkey";
import { useDispatch } from "react-redux";
import { searchActions } from "store/search/searchSlice";
import QuickSearchButton from "components/search/QuickSearchButton/QuickSearchButton";

export default function QuickSearchButtonWrapper(): JSX.Element {
  const dispatch = useDispatch();
  const toggleQuickSearch = () => {
    dispatch(searchActions.toggleSearchVisible());
  };

  const [searchText, setSearchText] = useState("⌘ K");

  const onKeyDown = (e) => {
    if (isHotkey("mod+k", e)) {
      toggleQuickSearch();
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    // const { userAgent } = window.navigator;
    if (window.navigator.userAgent.indexOf("Mac") === -1) {
      setSearchText("CTRL K");
    }
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <QuickSearchButton className="bg-gray-100 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900 max-w-32 md:max-w-64 sm:text-sm ring-2 ring-inset ring-gray-200 dark:ring-gray-500 rounded-lg px-2 py-2 flex justify-between items-center transition-all cursor-pointer">
      <>
        <div className="flex flex-row items-center">
          <Icon
            icon={faSearch}
            className="h-5 w-5 fill-gray-700 dark:fill-gray-400"
          />
          <span className="ml-2 font-semibold text-gray-700 dark:text-gray-400 whitespace-nowrap">
            Search
          </span>
        </div>
        <span className=" cursor-pointer ml-12 bg-gray-300 dark:bg-gray-700 text-gray-600 dark:text-gray-300 px-1 py-0.5 rounded font-bold whitespace-nowrap">
          {searchText}
        </span>
      </>
    </QuickSearchButton>
  );
}
