import Text from "hardcover-ui/components/Text";
import Icon from "hardcover-ui/components/Icon";
import Link from "next/link";
import React from "react";

interface Props {
  url: string;
  icon?: any;
  text?: string;
  children?: React.ReactElement[];
}
export const MenuClass =
  "flex gap-2 items-center p-1 hover:bg-indigo-100 dark:hover:bg-indigo-700 font-semibold rounded-md group/link";
export default function UserMenuItem({ url, icon, text, children }: Props) {
  return (
    <Link className={MenuClass} href={url}>
      {children ? (
        <>{children}</>
      ) : (
        <>
          <Icon
            icon={icon}
            className="text-gray-400 group-hover/link:text-indigo-300"
          />
          <Text>{text}</Text>
        </>
      )}
    </Link>
  );
}
