"use client";

import { useNativeSafeArea } from "hooks/uiSpecific/useNativeSafeArea";
import { ReactElement } from "react";

export default function MobileBottomNavWrapper({
  children,
}: {
  children: ReactElement;
}): JSX.Element {
  const { stylesObject: nativeSafeAreaConditionalPaddingStyles } =
    useNativeSafeArea("--safe-area-inset-bottom", "paddingBottom");

  return (
    <div
      className="flex items-center justify-between pb-4 pt-3 px-8 text-xs sm:text-sm"
      style={nativeSafeAreaConditionalPaddingStyles}
    >
      {children}
    </div>
  );
}
