"use client";

import React from "react";
import { Clipboard } from "@capacitor/clipboard";
import { Capacitor } from "@capacitor/core";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { getUserId } from "store/currentUser/currentUserSelector";
import { usePathname } from "next/navigation";
import useMount from "hooks/useMount";

const CopyLinkWrapper = ({ children }) => {
  const pathName = usePathname();
  const currentUserId = useSelector(getUserId);
  const mounted = useMount();

  const copyToClipboard = (e) => {
    e.preventDefault();
    const referrer = currentUserId ? `?referrer_id=${currentUserId}` : "";
    const url = `https://hardcover.app${pathName}${referrer}`;
    if (Capacitor.getPlatform() === "android") {
      Clipboard.write({ string: url }).then(() => {
        toast.success(`${currentUserId ? "Referral link" : "Link"} copied!`);
      });
    } else {
      navigator.clipboard.writeText(url);
      toast.success(`${currentUserId ? "Referral link" : "Link"} copied!`);
    }
  };

  if (!mounted) {
    return null;
  }

  // Clone the child element and add the onClick event
  const childWithCopy = React.cloneElement(children, {
    onClick: copyToClipboard,
    ...children.props,
  });

  return childWithCopy;
};

export default CopyLinkWrapper;
