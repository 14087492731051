"use client";

import useTheme from "features/ThemeManager/hooks/useTheme";

/* eslint-disable react/jsx-props-no-spreading */

export default function DropdownIndicator(props) {
  const { theme } = useTheme();
  const isDark = theme === "dark";

  return (
    <svg
      viewBox="0 0 42 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9862 12.5002L13 13.5H29V13V12.5C27.5193 12.5 26.1876 11.5991 25.6367 10.2246L24.8436 8.24581C23.468 4.81366 18.5978 4.84686 17.2691 8.29744L16.6475 9.91185C16.0612 11.4344 14.6171 12.4553 12.9862 12.5002Z"
        fill={isDark ? "#111827" : "#FFFFFF"} // dark fill or light fill
        stroke={isDark ? "#374151" : "#E5E7EB"} // dark stroke or light stroke
      />
      <g filter="url(#filter0_dd_7829_18173)">
        <path
          d="M29.2502 13H30V15H12V13H12.7498H12.7505C15.2765 12.9997 17.507 11.3521 18.2499 8.93773C19.0832 6.22956 22.9168 6.22956 23.7501 8.93773C24.493 11.3523 26.7239 13 29.2502 13Z"
          fill={isDark ? "#111827" : "#FFFFFF"}
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_7829_18173"
          x={0}
          y={4.90662}
          width={42}
          height={32.0934}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius={2}
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_7829_18173"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={3} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7829_18173"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius={3}
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_7829_18173"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={7.5} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_7829_18173"
            result="effect2_dropShadow_7829_18173"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_7829_18173"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
