/* eslint-disable import/no-cycle */
import faHouse from "icons/solid/house.svg";
import faSearch from "icons/solid/magnifying-glass.svg";
import faTelescope from "icons/solid/telescope.svg";
import faChartUp from "icons/regular/chart-line-up.svg";
import QuickSearchButton from "components/search/QuickSearchButton/QuickSearchButton";
import HideIfKeyboardVisible from "components/shared/HideIfKeyboardVisible";
import CurrentUserLibraryLink from "./components/CurrentUserLibraryLink";
import MobileBottomNavLink from "./components/MobileBottomNavLink";
import MobileBottomNavWrapper from "./components/MobileBottomNavWrapper";
import MobileBottomNavContent from "./components/MobileBottomNavContent";

export const ActiveLinkStyle = "text-gray-900 dark:text-white font-semibold";
export const InactiveLinkStyle =
  "text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white font-semibold";

export default function MobileFooter(): JSX.Element {
  return (
    <HideIfKeyboardVisible>
      <>
        <div className="xl:hidden" style={{ height: "120px" }} />
        <nav className="fixed xl:hidden bottom-0 bg-white/70 dark:bg-gray-800/70 backdrop-blur border-t dark:border-gray-700 w-full z-40 shadow-lg">
          <MobileBottomNavWrapper>
            <>
              <MobileBottomNavLink href="/home" icon={faHouse} text="Home" />
              <CurrentUserLibraryLink />
              <MobileBottomNavLink
                href="/explore"
                icon={faTelescope}
                text="Explore"
              />
              <MobileBottomNavLink
                href="/trending/month"
                icon={faChartUp}
                text="Trending"
              />

              <QuickSearchButton className={InactiveLinkStyle}>
                <MobileBottomNavContent icon={faSearch} text="Search" />
              </QuickSearchButton>
            </>
          </MobileBottomNavWrapper>
        </nav>
      </>
    </HideIfKeyboardVisible>
  );
}
