"use client";

import { ReactElement } from "react";
import { useIsCurrentPageInitial } from "hooks/useIsCurrentPageInitial";
import { useIsScreenSizeBiggerThanTailwindSize } from "hooks/useIsScreenSizeBiggerThanTailwindSize";
import { usePathname } from "next/navigation";
import { useSelector } from "react-redux";
import { getIsLoggedIn } from "store/currentUser/currentUserSelector";
import Backlink from "./Backlink";
import LogoLink from "./LogoLink";

const LogoOrBackLink = ({ children }: { children: ReactElement }) => {
  const pathname = usePathname();
  const isPageInitial = useIsCurrentPageInitial();
  const isDesktopVersionActive = useIsScreenSizeBiggerThanTailwindSize("lg");
  const isHomePage = pathname && pathname.includes("/home");
  const isLoggedIn = useSelector(getIsLoggedIn);
  const shouldLogoLinkBeShown =
    isHomePage || isDesktopVersionActive || isPageInitial;

  if (shouldLogoLinkBeShown && !isLoggedIn) {
    return children;
  }
  if (shouldLogoLinkBeShown && isLoggedIn) {
    return <LogoLink url="/home" />;
  }
  return <Backlink className="pl-2 lg:pl-0" />;
};

export default LogoOrBackLink;
