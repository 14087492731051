"use client";

import QuickSearchButtonWrapper from "features/Nav/components/Nav/DesktopNav/DesktopNavLinks/QuickSearchButtonWrapper";
import OnboardHider from "components/shared/OnboardHider";
import { UserType } from "types";
import React, { startTransition, useState } from "react";

import Button from "hardcover-ui/components/Button";
import Icon from "hardcover-ui/components/Icon";

import chevronDown from "icons/solid/chevron-down.svg";
import sparkles from "icons/solid/sparkles.svg";
import books from "icons/solid/book-copy.svg";

import classNames from "lib/classNames";
import DiscoverMenu from "./DesktopNavLinks/DropdownMenus/DiscoverMenu/DiscoverMenu";
import BrowseMenu from "./DesktopNavLinks/DropdownMenus/BrowseMenu/BrowseMenu";
import UserSpecificMenuWrapper from "./UserSpecificMenuWrapper";
import DropdownIndicator from "./DropdownIndicator";

export default function DesktopNavLinks({ user }: { user?: UserType }) {
  const [hoveredButton, setHoveredButton] = useState(null);
  const [lastHoveredButton, setLastHoveredButton] = useState(null);

  const [dropdownHovered, setDropdownHovered] = useState(false);

  const isButtonHovered = (button) =>
    hoveredButton === button || (dropdownHovered && hoveredButton === button);

  const changeHoveredButton = (button: "discover" | "browse" | null) => {
    startTransition(() => {
      setHoveredButton(button);
      setLastHoveredButton(button ? hoveredButton : null);
    });
  };

  return (
    <div className="hidden lg:flex space-x-2 justify-end items-center">
      <OnboardHider user={user}>
        <>
          <QuickSearchButtonWrapper />

          <div
            className="relative flex gap-2 group/dropdown"
            onMouseLeave={() => {
              if (!dropdownHovered) {
                changeHoveredButton(null);
              }
            }}
          >
            <div
              className="-m-2 group/discover peer/discover"
              onMouseEnter={() => changeHoveredButton("discover")}
            >
              <Button
                className={`px-4 py-2 font-semibold  ${
                  isButtonHovered("discover")
                    ? "dark:bg-gray-900 bg-gray-200 text-gray-900 dark:text-white"
                    : "dark:bg-gray-900/30 bg-gray-300/30"
                } transition-colors rounded-xl cursor-pointer m-2 backdrop-blur-sm`}
              >
                <Icon icon={sparkles} size="sm" className="opacity-50 " />
                Discover
                <Icon
                  icon={chevronDown}
                  size="sm"
                  className={`transform transition-transform duration-50 ease-out ${
                    isButtonHovered("discover") ? "rotate-180" : ""
                  }`}
                />
              </Button>
            </div>

            <div
              className="-m-2 group/browse peer/browse"
              onMouseEnter={() => changeHoveredButton("browse")}
            >
              <Button
                className={`px-4 py-2 font-semibold  ${
                  isButtonHovered("browse")
                    ? "dark:bg-gray-900 bg-gray-300 text-gray-900 dark:text-white"
                    : "dark:bg-gray-900/30 bg-gray-300/30"
                } transition-colors rounded-xl cursor-pointer m-2 backdrop-blur-sm`}
              >
                <Icon icon={books} size="sm" className="opacity-50 " /> Browse
                <Icon
                  icon={chevronDown}
                  size="sm"
                  className={`transform transition-transform duration-50 ease-out ${
                    isButtonHovered("browse") ? "rotate-180" : ""
                  }`}
                />
              </Button>
            </div>

            <div className="absolute top-6 -mt-px z-20 hidden group-hover/dropdown:block pointer-events-none">
              <DropdownIndicator
                className={classNames(
                  "w-20",
                  lastHoveredButton
                    ? "transition-all duration-100 motion-reduce:duration-0"
                    : ""
                )}
                style={{
                  transform:
                    hoveredButton === "browse"
                      ? "translateX(268%)"
                      : "translateX(98%)",
                }}
              />
            </div>

            <div
              onMouseEnter={() => setDropdownHovered(true)}
              onMouseLeave={() => {
                startTransition(() => {
                  setDropdownHovered(false);
                  changeHoveredButton(null);
                });
              }}
              className="absolute w-full ring-2 ring-gray-200 dark:ring-gray-700 top-10 left-0 mt-2 invisible group-hover/dropdown:visible bg-white dark:bg-gray-900 rounded-lg shadow-lg overflow-hidden"
            >
              <div
                className={classNames(
                  "flex",
                  lastHoveredButton
                    ? "transition-transform duration-300 motion-reduce:duration-0 ease-in-out"
                    : ""
                )}
                style={{
                  transform:
                    hoveredButton === "browse"
                      ? "translateX(-100%)"
                      : "translateX(0%)",
                }}
              >
                <div className="min-w-full h-full">
                  <DiscoverMenu user={user} />
                </div>
                <div className="min-w-full h-full">
                  <BrowseMenu />
                </div>
              </div>
            </div>
          </div>
        </>
      </OnboardHider>
      <UserSpecificMenuWrapper user={user} />
    </div>
  );
}
