"use client";

/* eslint-disable @typescript-eslint/no-empty-function */
import Button from "hardcover-ui/components/Button";
import { ImageType } from "types";
import CurrentUserAvatar from "components/shared/CurrentUserAvatar";
import NotificationIndicator from "features/Nav/components/Nav/parts/NotificationIndicator";
import classNames from "lib/classNames";
import LoadingSpinner from "hardcover-ui/components/LoadingSpinner";

type Props = {
  onClick?: () => void;
  image?: ImageType;
  active?: boolean;
  loading?: boolean;
};

export default function ToggleSidebarButton({
  image,
  onClick = () => {},
  active = false,
  loading = false,
}: Props) {
  return (
    <div className="flex justify-center mr-1">
      <Button
        onMouseUp={onClick}
        className={classNames(
          "relative bg-gray-800 rounded-full text-sm text-white",
          active
            ? "outline-none ring-2 ring-offset ring-offset-gray-800 ring-white"
            : "focus:outline-none focus:ring-2 focus:ring-offset focus:ring-offset-gray-800 focus:ring-white"
        )}
      >
        <span className="sr-only">Open user menu</span>
        {loading ? (
          <div className="absolute bottom-0 right-0 w-4 h-4 bg-gray-100 dark:bg-gray-900 rounded-full">
            <LoadingSpinner size="sm" />
          </div>
        ) : (
          false
        )}
        <NotificationIndicator />
        <CurrentUserAvatar image={image} size="md" />
      </Button>
    </div>
  );
}
