"use client";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "hardcover-ui/components/Avatar";
import { AvatarThemeProps } from "hardcover-ui/theme/components/avatar";
import { userLoadingState } from "store/currentUser/currentUserSelector";
import useCurrentUser from "hooks/fragments/useCurrentUser";
import { ImageType } from "types";

export default function CurrentUserAvatar({
  size = "md",
  image,
}: {
  image: ImageType;
  size: AvatarThemeProps["size"];
}) {
  const { userLoaded } = useSelector(userLoadingState);
  const [activeImage, setActiveImage] = useState(image);
  const cachedImage = useCurrentUser((u) => u.cachedImage);

  useEffect(() => {
    if (userLoaded && cachedImage && activeImage?.url !== cachedImage?.url) {
      setActiveImage(cachedImage);
    }
  }, [userLoaded, cachedImage]);

  return (
    <Avatar size={size} image={activeImage} alt="Your Avatar" lazy={false} />
  );
}
