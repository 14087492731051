"use client";

/* eslint-disable import/no-cycle */
import faBookOpen from "icons/solid/book-open.svg";
import useCurrentUser from "hooks/fragments/useCurrentUser";
import useMount from "hooks/useMount";
import MobileBottomNavLink from "./MobileBottomNavLink";

export default function MobileBottomNav(): JSX.Element {
  const username = useCurrentUser((u) => u.username);
  const mounted = useMount();

  return (
    <MobileBottomNavLink
      href={username && mounted ? `/@${username}/books` : "/join"}
      text="Library"
      icon={faBookOpen}
    />
  );
}
