"use client";

import { useRouter } from "next/navigation";
import { signOut } from "next-auth/react";
import classNames from "lib/classNames";
import {
  menuIconTheme,
  menuItemTheme,
} from "hardcover-ui/theme/components/menu";
import { useCurrentSession } from "hooks/useCurrentSession";
import Button from "hardcover-ui/components/Button";
import Icon from "hardcover-ui/components/Icon";
import faSignOut from "icons/duotone/arrow-right-from-bracket.svg";
import { toast } from "sonner";
import Link from "next/link";

interface Props {
  active?: boolean;
}

export default function LogoutLinkActive({ active = false }: Props) {
  const { resetSession } = useCurrentSession();
  const router = useRouter();

  return (
    <>
      <Button
        variant="unstyled"
        size="md"
        className={classNames(
          active
            ? menuItemTheme.variant.primaryActive
            : menuItemTheme.variant.primary,
          "w-full rounded-md py-3 focus:outline-none group"
        )}
        onClick={(e) => {
          e.preventDefault();
          signOut({ redirect: false, callbackUrl: "/" }).then(() => {
            toast.success("You have been logged out. Redirecting...");
            resetSession().then(() => {
              router.push("/");
            });
          });
        }}
      >
        <Icon
          icon={faSignOut}
          size="lg"
          className={classNames(
            active
              ? `${menuIconTheme.variant.primaryActive}`
              : `${menuIconTheme.variant.primary} `
          )}
        />
        Sign out
      </Button>

      {/* Preload the homepage to make the redirect faster */}
      <Link className="hidden" href="/">
        Home
      </Link>
    </>
  );
}
