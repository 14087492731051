"use client";

import { Suspense, lazy, useState } from "react";
import { ImageType } from "types";
import ToggleSidebarButton from "./components/ToggleSidebarButton";

const MobileUserNavSidepanelActive = lazy(
  () => import("./MobileUserNavSidepanelActive")
);

export default function MobileUserNavSidepanel({
  image,
}: {
  image: ImageType;
}) {
  const [clicked, setClicked] = useState(false);

  // Don't load Headless UI and the entire dropdown component unless it's clicked on
  if (clicked) {
    return (
      <Suspense fallback={<ToggleSidebarButton image={image} loading />}>
        <MobileUserNavSidepanelActive image={image} />
      </Suspense>
    );
  }

  return <ToggleSidebarButton image={image} onClick={() => setClicked(true)} />;
}
