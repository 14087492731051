"use client";

import useMount from "hooks/useMount";
import { Keyboard } from "@capacitor/keyboard";
import { ReactElement, useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";

export default function HideIfKeyboardVisible({
  children,
}: {
  children: ReactElement;
}) {
  const mounted = useMount();
  const [keyboardShowing, setKeyboardShowing] = useState(false);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener("keyboardDidShow", () => setKeyboardShowing(true));
      Keyboard.addListener("keyboardDidHide", () => setKeyboardShowing(false));
      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, []);

  if (
    mounted &&
    keyboardShowing &&
    Capacitor.isNativePlatform() &&
    Capacitor.getPlatform() === "ios"
  ) {
    return false;
  }

  return <>{children}</>;
}
