import Button from "hardcover-ui/components/Button";
import JulesRocket from "app/(header)/(marketing)/parts/svgs/JulesRocket";

export default function PricingLink() {
  return (
    <Button
      as={{
        element: "link",
        props: {
          href: "/pricing",
        },
      }}
      variant="tertiary"
      size="md"
      className="font-semibold  group"
    >
      <JulesRocket className="w-16 -m-6 -ml-5 saturate-50 group-hover:saturate-100" />
      Subscribe
    </Button>
  );
}
