"use client";

import {
  getUserWithFilter,
  CurrentUserFilter,
} from "store/currentUser/currentUserSelector";
import { useSelector } from "react-redux";

function defaultFilter(user) {
  return user;
}
export default function useCurrentUser(filter?: CurrentUserFilter) {
  const f = filter || defaultFilter;
  return useSelector((state) => {
    return getUserWithFilter(state, f);
  });
}
