import { CSSProperties, useEffect, useState } from "react";

export const useNativeSafeArea = (
  safeAreaCssVariable: string,
  propName?: string
): { stylesObject: CSSProperties; sizeAsNumber?: number } => {
  const [stylesObject, setStylesObject] = useState({});
  const [sizeAsNumber, setSizeAsNumber] = useState(0);

  useEffect(() => {
    const getSizAsNumber = (): number | undefined => {
      return (
        Number(
          globalThis
            .getComputedStyle?.(globalThis.document?.documentElement)
            .getPropertyValue(safeAreaCssVariable)
            .replace(/\D/g, "")
        ) ?? undefined
      );
    };
    setSizeAsNumber(getSizAsNumber());

    if (propName) {
      const getStylesObject = () => {
        return getSizAsNumber() !== 0
          ? {
              [propName]: `var(${safeAreaCssVariable})`,
            }
          : {};
      };

      setStylesObject(getStylesObject());
    }
  }, [globalThis, safeAreaCssVariable, propName]);

  return { stylesObject, sizeAsNumber };
};
