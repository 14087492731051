import classNames from "lib/classNames";
import { ReactNode } from "react";

export default function CountBadge({
  active = false,
  className = "",
  children,
}: {
  active?: boolean;
  children: ReactNode;
  className?: string;
}) {
  return (
    <span
      className={classNames(
        className,
        active
          ? "bg-indigo-600 text-white dark:bg-yellow-400 dark:text-yellow-900"
          : "bg-stone-300 dark:bg-gray-600",
        "break-keep ml-2 rounded-lg text-sm  px-1 py-0.5 text-stone-800 dark:text-gray-300"
      )}
    >
      {children}
    </span>
  );
}
