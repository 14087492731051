import Icon from "hardcover-ui/components/Icon";

type Props = {
  icon: any;
  text: string;
};

export default function MobileBottomNavContent({ text, icon }: Props) {
  return (
    <p className="flex flex-col items-center space-y-1">
      <Icon icon={icon} size="2xl" />
      <span>{text}</span>
    </p>
  );
}
