"use client";

import Icon from "hardcover-ui/components/Icon";
import Badge from "hardcover-ui/components/Badge";
import useCurrentUser from "hooks/fragments/useCurrentUser";
import NotificationIndicator from "features/Nav/components/Nav/parts/NotificationIndicator";
import bell from "icons/solid/bell.svg";
import Text from "hardcover-ui/components/Text";
import UserMenuItem from "./UserMenuLink";

export default function MenuNotifications() {
  const currentNotificationsCount = useCurrentUser(
    (u) => u.notificationsCount || 0
  );

  return (
    <UserMenuItem url="/notifications">
      <div className="relative">
        <Icon
          icon={bell}
          className="text-gray-400 group-hover/link:text-indigo-300"
        />
        <NotificationIndicator />
      </div>
      <Text>Notifications</Text>
      {currentNotificationsCount > 0 ? (
        <Badge className="ml-2" size="sm" variant="warning">
          {currentNotificationsCount.toLocaleString()}
        </Badge>
      ) : (
        <></>
      )}
    </UserMenuItem>
  );
}
