/* eslint-disable import/no-cycle */
import ActiveLink from "components/ActiveLink";
import MobileBottomNavContent from "./MobileBottomNavContent";
import { ActiveLinkStyle, InactiveLinkStyle } from "../MobileFooter";

type Props = {
  href: string;
  icon: any;
  text: string;
};

export default function MobileBottomNavLink({ href, text, icon }: Props) {
  return (
    <ActiveLink
      href={href}
      activeClassName={ActiveLinkStyle}
      className={InactiveLinkStyle}
      aria-current="page"
    >
      <MobileBottomNavContent icon={icon} text={text} />
    </ActiveLink>
  );
}
